import React, { Component } from "react";
import { Container } from "reactstrap";
//Import Components
import SectionTitle from "../../components/Common/SectionTitle";
import Summary from "./summary";
import Manifest from "./manifest";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSummary: true
    };
  }

  toggleButton() {
    this.setState({ isSummary: !this.state.isSummary });
    document.getElementById("id-manifest").click();
  }

  render() {
    const buttonLabel = (this.state.isSummary ? 'Llegeix tot el manifest' : 'Tornar');

    return (
      <React.Fragment>
        <section className="section bg-light position-relative" id="manifest">
          <Container>
            <SectionTitle
              title="MANIFEST"
              description="MANIFEST UNITARI EN CONTRA DELS MACRO PROJECTES EÒLICS I SOLARS I LES LÍNIES MAT I
              LAT"
            />
            {this.state.isSummary ? <Summary /> : <Manifest />}
            <div className="flex-center">
              <button
                type="button"
                className="btn btn-outline-purple mt-4"
                onClick={() => this.toggleButton()}
              >
                {buttonLabel}
              </button>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
