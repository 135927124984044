import React, { Component } from "react";
import { Row } from "reactstrap";

class Manifest extends Component {
  render() {
    return (
      <Row className="align-items-center">
        <div className="pr-lg-5 mt-4 px-3">
          <p className="text-muted mb-4 f-15" style={{ textAlign: `justify` }}>
            Les PLATAFORMES, ENTITATS I PERSONES adherides al present MANIFEST,
            <br />
            <br />
            <b><u>EXPOSEM</u></b> que l'actual desenvolupament de la transició energètica a Catalunya, feta en nom
            de les energies renovables, <b>contradiu la voluntat, les intencions i l'esperit de les lleis
              dictades per la mateixa administració catalana</b> que es concreten en el Pacte Nacional per la
            transició energètica de 2017 (SOLARCAT), actualitzat el 9 de juliol de 2019 i impulsat pel decret
            24/2021 i d'acord també per la llei 16/2017 de canvi climàtic, basat, tot plegat, en l'eficiència
            energètica i les energies renovables i que ha de ser, segons l'article 19 amb el foment de
            l'autoconsum, la participació d'actors locals en la producció i distribució; inclusiu i de generació
            distribuïda, aprofitant espais alterats per l'acció humana, tot minimitzant l'ocupació del
            territori, la implantació de xarxes de distribució d'energia intel.ligents i tancades i que
            garanteixin l'accés a l'energia com a bé comú.
            <br />
            <br />
            <b><u>DENUNCIEM:</u></b>
            <ol>
              <li>
                L'amenaça continuada <b>en territori català</b> de situar en terres de conreu, actives o no, noves
                instal.lacions de parcs eòlics i solars i les conseqüents línies d'evacuació i transport.
              </li>
              <li>
                L'evidència anunciada als mitjans que a <b>les terres d'Aragó</b> es pensen instal.lar macro parcs
                solars per produir més de 15.000MW i que per al transport de la mateixa caldrà una gran
                estesa de línies LAT i MAT que afectaran més de 68.000 ha de les quals el 60'2 % seran sobre
                sòl agrari, 8.000 ha sobre sòl protegit per XN2000, i altres 8.000 dins del Pla d'Espais d'Interès
                Natural (PEIN). L'Aragó perdrà zones de cultiu i a Catalunya afectarà municipis de les
                comarques de Tarragona com Terra Alta, La Ribera d'Ebre, el Priorat, l'Alt i Baix Camp.
              </li>
              <li>
                A les <b>comarques de Lleida</b> les línies projectades afectaran El Segrià, Les Garrigues, La
                Segarra...A les <b>de Girona</b>, Gironès, Alt Empordà, La selva... A <b>Barcelona</b>: l'Alt i Baix Penedès,
                Baix Llobregat... amb les poblacions de Begues, Abrera...entre altres . De moment 400km de
                línies MAT afectaran més de 15 comarques de tot Catalunya i les poblacions corresponents.
              </li>
              <li>
                Caldrà tenir en compte les <b>línies provinents dels Marroc</b> que transportaran la llum a Europa
                o a les grans ciutats, trinxant de nou conreus i zones protegides.
              </li>
              <li>
                Tota aquesta activitat es fa amb <b>l'expropiació forçosa</b> per part del govern central quan els
                parcs són superiors a 50 MW o <b>la connivència i el permís de l'administració catalana</b> quan
                tenen menys de 50MW. Sense consultar, generalment, els propietaris ni el veïnat, també
                afectat per la proximitat de les línies MAT, algunes <b>situades a menys de 100 m. dels
                  habitatges</b>, quan hauria de ser de 450 m.
              </li>
              <li>
                Caldrà un 5% del territori català per aconseguir prou energia, això equivaldrà a 65.000 ha
                que ja es comencen a ocupar.
              </li>
            </ol>
            <br />
            PÈRDUES COM A SOCIETAT:
            <br />
            <ol>
              <li>
                <b>Terrenys de conreu</b> i per tant <b>sobirania alimentària</b>, ara que s'apropa la gran crisi.
              </li>
              <li>
                <b>La Bellesa del paisatge</b>, ara que és un valor a l'alça amb la sensibilització, l'agroturisme...
              </li>
              <li>
                <b>Un espai on fer salut</b>. Ja que les torres LAT i MAT alliberen electricitat electromagnètica 
                perniciosa que pot arribar a provocar, entre altres dolències (cefalees, insomni...) fins i tot, 
                l'Agència Internacional per la investigació del càncer  de l'Organització Mundial de la Salut 
                estableix les ones electromagnètiques com a possible carcinogen.
              </li>
              <li>
                Perdrem la pregonada <b>sobirania energètica</b>, ja que continuarem depenent dels oligopolis de
                sempre i del preu abusiu de l'energia que el fan dependre del gas.
              </li>
              <li>
                Avifauna diversa, 33.000 cada any, com les cigonyes, a tot l'estat per causa directa de les
                línies MAT.
              </li>
            </ol>
            <br />
            <b><u>DEMANEM:</u></b>
            <br />
            <ol>
              <li>Que <b>l'administració catalana desenvolupi el PLA TERRITORIAL DE TRANSICIÓ ENERGÈTICA</b>,
                al marge dels oligopolis amb empreses del país que donen feina a la gent.
              </li>
              <li>Que es faci la transició <b>amb la implicació de la gent del territori i de les entitats,
                Plataformes</b> i associacions sensibles amb el tema.
              </li>
              <li>Que es <b>respectin els sòls agraris i es protegeixi el sector primari</b>. Llogar la terra per posar
                plaques o molins no és una sortida digna per poder subsistir, sinó que cal que <b>s'assegurin els
                  preus de producció</b> per tal de <b>frenar l'èxode</b> del camp.
              </li>
              <li><b>L'energia solar cal que estigui d'acord amb les lleis dictades</b>: d'<b>autoconsum</b>, de generació
                distribuïda, sobre teulats públics i privats...amb lleis adequades per propiciar les <b>comunitats
                  energètiques</b>. Amb capacitat, segons estudis, per produir un 40% de l'energia necessària.
              </li>
              <li>Que <b>l'administració surti del bucle d'energies eòliques i solars</b> tot ocupant la terra, i <b>estudiï
                altres fonts d'energia</b> amb moltes possibilitats: hidràulica amb bombeig reversible de pantans
                i embassaments, geotèrmia, hidrogen verd...
              </li>
              <li>Que si cal fer parcs solars o eòlics <b>siguin en zones antropitzades</b>, com les 29.000 ha de
                polígons industrials que tenim a Catalunya, 13.000 dels quals són a l'àrea de Barcelona, també
                en zones periurbanes degradades, de concentració d'infraestructures, portuàries... és a dir
                amb nul valor ambiental, agrari, paisatgístic etc.
              </li>
              <li>Que la producció d'energia estigui <b>a prop dels llocs de consum</b> i si cal línies de transport que
                <b>s'utilitzin les existents</b>, sabem que és possible.
              </li>
              <li>Que es continuïn donant <b>subvencions per potenciar l'autoconsum i les comunitats</b>
                energètiques, l'eficiència dels tancaments i aïllants, potenciant l'estalvi...
              </li>
            </ol>
            <br />
            <b><u>MANIFESTEM</u></b>
            <br />
            <ol>
              <li>
                El nostre <b>REBUIG a l'actual model energètic depenent dels oligopolis tradicionals</b>, ara
                obsessionats en invertir en macro parcs eòlics i solars amb totes les conseqüències negatives
                exposades.
              </li>
            </ol>
            <br />
            <b><u>EXIGIM</u></b> que l'administració que ens representa vetlli per:
            <ol>
              <li>
                <b>La sobirania energètica</b>, potenciant altres fonts d'energia, que hi són.
              </li>
              <li>
                <b>La sobirania alimentària</b> amb preus garantits per l'agricultura i els productes de proximitat.
              </li>
              <li>
                <b>La preservació dels ecosistemes naturals, la salut</b> de l'entorn, la salut de les persones... <b>i la
                  bellesa</b> dels paisatges, valors que no volem perdre.
              </li>
            </ol>
            <hr />
            Plataformes, Entitats i Associacions adherides:,
            <br />
            <br />
            Preservem l'Anoia, Bellprat Sobirania Energètica, Sobirania Energètica Baixa Segarra, No a la
            MAT Alt Penedès, No a la MAT Baix Penedès, Plataforma Lleida contra la MAT, Garrigues
            Sostenibles, No a la MAT Terres de Ponent, Plataforma del Priorat, Ajuntament de Bellprat, La
            Vall Sostenible...
          </p>
        </div>
      </Row>
    );
  }
}

export default Manifest;
