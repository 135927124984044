import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

//Import Image
import HomeImage from "../assets/images/home-image.jpg";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-3-bg position-relative"
          id="home"
          style={{ background: `url(${HomeImage}) center center`, backgroundSize: `cover` }}
        >
          <div className="bg-dark-overlay" />
          <Container>
            <Row className=" justify-content-center">
              <Col lg={8}>
                <div className="text-center">
                  <h1 className="text-white hero-3-title mb-4 line-height-1_4">
                    No a la MAT Catalunya
                  </h1>
                  <p className="text-white-50 w-75 mx-auto f-20">
                    Per una xarxa distribuïda i no centralitzada
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={8}>
                <div className="text-center Subcribe-form mt-5">
                  <p className="text-white-50 w-75 mx-auto f-15">
                    Visualitza el traçat de les MATS a Catalunya a través de l'ICGC
                  </p>
                  <a href="https://www.instamaps.cat/instavisor/bf3dbae2c7aa42d5b63dad7511f531f2/MAT_del_cluster_BEGUES_539_AC.html" target="_blank">
                    <button
                      type="button"
                      className="btn rounded-pill btn-purple ml-2"
                    >
                      INSTAMAPS
                    </button>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
