import React, { Component } from "react";

class Detail extends Component {

  body1() {
    return (
      <div>
        <span>Aquests projectes provocaran pèrdues com a societat</span>
        <ol>
          <li>
            Terrenys de conreu i per tant sobirania alimentària, ara que s'apropa la gran crisi.
          </li>
          <li>
            La Bellesa del paisatge, ara que és un valor a l'alça amb la sensibilització, l'agroturisme...
          </li>
          <li>
            Un espai on fer salut. Ja que les torres LAT i MAT alliberen electricitat electromagnètica perniciosa que pot arribar a provocar, entre altres dolències (cefalees, insomni...) fins i tot el càncer  segons l'Agència Internacional per la investigació del càncer i segons l'OMS.
          </li>
          <li>
            Perdrem la pregonada sobirania energètica, ja que continuarem depenent dels oligopolis de sempre i del preu abusiu de l'energia que el fan dependre del gas.
          </li>
          <li>
            Avifauna diversa, 33.000 cada any, com les cigonyes, a tot l'estat per causa directa de les línies MAT.
          </li>
        </ol>
      </div>
    )
  }

  body3() {
    return (
      <div>
        <ol>
          <li>
            Que l'administració catalana desenvolupi el PLA TERRITORIAL DE TRANSICIÓ ENERGÈTICA, al marge dels oligopolis amb empreses del país que donen feina a la gent.
          </li>
          <li>
            Que es faci la transició amb la implicació de la gent del territori i de les entitats, Plataformes  i associacions sensibles amb el tema.
          </li>
          <li>
            Que es respectin els sòls agraris i es protegeixi  el sector primari. Llogar la terra per posar plaques o molins no és una sortida digna per poder subsistir, sinó que cal que s'assegurin els preus de producció per tal de frenar l'èxode del camp.
          </li>
          <li>
            L'energia solar cal que estigui d'acord amb les lleis dictades: d'autoconsum, de generació distribuïda, sobre teulats públics i privats...amb lleis adequades per propiciar les comunitats energètiques. Amb capacitat, segons estudis,  per produir un 40% de l'energia necessària.
          </li>
          <li>
            Que l'administració surti del bucle d'energies eòliques i solars tot ocupant la terra, i estudiï altres fonts d'energia amb moltes possibilitats: hidràulica amb bombeig reversible de pantans i embassaments, geotèrmia, hidrogen verd...
          </li>
          <li>
            Que si cal fer parcs solars o eòlics siguin en zones antropitzades, com les 29.000 ha de polígons industrials que tenim a Catalunya, 13.000 dels quals són a l'àrea de Barcelona, també en zones periurbanes degradades,  de concentració d'infraestructures,  portuàries... és a dir  amb nul valor ambiental, agrari, paisatgístic etc.
          </li>
          <li>
            Que la producció d'energia estigui a prop dels llocs de consum i si cal línies de transport  que s'utilitzin les existents, sabem que és possible.
          </li>
          <li>
            Que es continuïn donant subvencions per potenciar l'autoconsum i les comunitats energètiques, l'eficiència dels tancaments i aïllants, potenciant l'estalvi...
          </li>
        </ol>
      </div>
    )
  }

  render() {
    return (
      <div className="service-box active mt-4">
        <div className="btn-close-service" onClick={() => { this.props.onClick() }}>
          <i className="remixicon-close-fill h4 service-icon cursor-pointer" />
        </div>
        <i className={`${this.props.service.icon} text-purple h1 service-icon`} />
        <h5 className="text-dark font-weight-medium service-title mb-3 mt-5">
          {this.props.service.title}
        </h5>
        <p className="text-muted mb-4 f-15">
          {this.props.service.id === 1
            ? this.body1()
            : this.props.service.id === 3
              ? this.body3()
              : this.props.service.body
          }
        </p>
      </div>
    );
  }
}

export default Detail;
