import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../Common/SectionTitle";
import Detail from "./detail";
import ServiceBox from "./service-box";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 0,
          title: "Què són les línies MAT?",
          icon: "pe-7s-gleam",
          summary: "Les MAT són línies de Molt Alta Tensió, destinades al transport d'energia, amb l'objectiu de transportar energia des de l'Aragó a la província de Barcelona. En aquest moment hi ha projectats 3 línies MAT impulsades per una mateixa empresa privada, Forestàlia, que creuaran i destrossaran el territori català.",
          body: "Les MAT són línies de Molt Alta Tensió, destinades al transport d'energia, amb l'objectiu de transportar energia des de l'Aragó a la província de Barcelona. En aquest moment hi ha projectats 3 línies MAT impulsades per una mateixa empresa privada, Forestàlia, que creuaran i destrossaran el territori català.",
        },
        {
          id: 1,
          title: "Perquè diem NO a aquests projectes?",
          icon: "pe-7s-shield",
          summary: "Són projectes amb origen privat que provocaran pèrdues com a societat, entre ells: pèrdua de la sobirania alimentària i pèrdua de terrenys del sector primari, pèrdues en bellesa en el paisatge, possibles afectacions en salut, i pèrdues en la sobirania energètica. + ",
          body: ""
        },
        {
          id: 2,
          title: "Quins efectes té en la salut?",
          icon: "pe-7s-bandaid",
          summary: "L'Agència Internacional per la investigació del Càncer  de l'Organització Mundial  de la Salut estableix les ones electromagnètiques com a possible carcinogen. Per tant, cal establir un principi de precaució (d'un metre per cada kV, la qual cosa significa un mínim de 400 metres per cada costat en les línies de 400 kV.) per la seva projecció.",
          body: "L'Agència Internacional per la investigació del Càncer  de l'Organització Mundial  de la Salut estableix les ones electromagnètiques com a possible carcinogen. Per tant, cal establir un principi de precaució (d'un metre per cada kV, la qual cosa significa un mínim de 400 metres per cada costat en les línies de 400 kV.) per la seva projecció.",
        },
        {
          id: 3,
          title: "Què demanem?",
          icon: "pe-7s-speaker",
          summary: "Que es realitzi una transició energètica distribuïda i de proximitat, al marge dels oligopolis, amb implicació de la població del territori i les entitats implicades, i es protegeixi el sòl agrari i el sector primari. + ",
          body: ""
        }
      ],
      isDetail: false,
      objectDetail: null
    };
  }

  close() {
    this.setState({ isDetail: false, objectDetail: null });
  }

  handleClick(id) {
    this.setState({ isDetail: true, objectDetail: this.state.services[id] });
    document.getElementById("id-information").click();
  }

  render() {
    return (
      <React.Fragment>
        <section id="information" className="section position-relative">
          <Container>
            <SectionTitle
              title="Informació sobre la MAT"
            />
            {this.state.isDetail ?
              <Detail service={this.state.objectDetail} onClick={() => this.close()} />
              :
              <Row>
                <React.Fragment>
                  {this.state.services.map((service) => (
                    <div className="col-lg-6 col-md-6" key={service.id}>
                      <ServiceBox service={service} onClick={() => this.handleClick(service.id)} />
                    </div>
                  ))}
                </React.Fragment>
              </Row>
            }

          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
