import React, { Component } from "react";

class ClientBox extends Component {
    handleClick(url) {
        window.open(url, '_blank');
    }

    render() {
        return (
            <React.Fragment>
                {this.props.clients.map((client, key) => (
                    <div className="item" key={key}>
                        <div className="testi-content bg-white text-center m-3" style={{ minHeight: '265px' }}>
                            <img
                                src={client.img}
                                alt="client"
                                className="img-fluid mx-auto d-block rounded-circle user-img"
                            />
                            <h5 className="text-dark mt-4 f-18 mb-0">{client.name}</h5>
                            <p className="text-muted f-14">{client.technology}</p>
                            <div onClick={() => { this.handleClick(client.link) }}>
                                <i className="remixicon-share-box-fill h4 testi-icon cursor-pointer" />
                            </div>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

export default ClientBox;