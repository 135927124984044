import React, { Component } from "react";
import { Container } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="pt-5 pb-4 position-relative bg-light">
          <Container className="container">
            <FooterLinks />
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
