import React, { Component } from "react";
import { Row, Col } from "reactstrap";

//Import Images
import noMatImage from "../../assets/images/no-mat-image.png";

class Summary extends Component {
    render() {
        return (
            <Row className="align-items-center">
                <Col lg="6">
                    <div className="features-img mt-4">
                        <img
                            src={noMatImage}
                            alt="Fetures"
                            className="img-fluid mx-auto d-block"
                        />
                    </div>
                </Col>

                <div className="col-lg-5 offset-lg-1">
                    <div className="pr-lg-5 mt-4">
                        <p className="text-muted mb-4 f-15" style={{ textAlign: `justify` }}>
                            Les PLATAFORMES, ENTITATS I PERSONES adherides al present MANIFEST,
                            <br />
                            <br />
                            <b><u>EXPOSEM</u></b> que l'actual desenvolupament de la transició energètica a Catalunya, feta en nom
                            de les energies renovables, <b>contradiu la voluntat, les intencions i l'esperit de les lleis
                                dictades per la mateixa administració catalana</b> que es concreten en el Pacte Nacional per la
                            transició energètica de 2017 (SOLARCAT), actualitzat el 9 de juliol de 2019 i impulsat pel decret
                            24/2021 i d'acord també per la llei 16/2017 de canvi climàtic, basat, tot plegat, en l'eficiència
                            energètica i les energies renovables i que ha de ser, segons l'article 19 amb el foment de
                            l'autoconsum, la participació d'actors locals en la producció i distribució; inclusiu i de generació
                            distribuïda, aprofitant espais alterats per l'acció humana, tot minimitzant l'ocupació del
                            territori, la implantació de xarxes de distribució d'energia intel.ligents i tancades i que
                            garanteixin l'accés a l'energia com a bé comú.
                        </p>
                    </div>
                </div>
            </Row>
        );
    }
}

export default Summary;