import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {email: 'noalamatcatalunya@gmail.com'};
  }

  openEmail() {
    window.location = 'mailto:' + this.state.email;
  }

  render() {
    return (
      <React.Fragment>
        <section className="section cta-content position-relative bg-gradient" id="contact">
          <div className="bg-cta-overlay" />
          <Container className="container">
            <Row className="row align-items-center">
              <Col lg={6}>
                <h3 className="text-white mb-3">
                  Uneix-te, informat i contacta amb nosaltres!
                </h3>
                <p className="text-white-50 f-15 mb-0">
                  Escriu al següent correu {this.state.email} o clica <b>Contacta</b>
                </p>
              </Col>
              <Col lg={5}>
                <div className="cta-btn text-right mt-5 mt-lg-0">
                  <Link to="#" className="btn btn-light"
                  onClick={() => this.openEmail()}>
                    Contacta
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactUs;
