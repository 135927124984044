import React, { Component } from "react";
import { Container } from "reactstrap";

//Import Components
import SectionTitle from "../../components/Common/SectionTitle";
import ClientBox from "./clients-box"

//Slider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import laVallImg from "../../assets/images/client/lavall-img.jpg"
import ponentImg from "../../assets/images/client/ponent-img.jpg"
import isonaImg from "../../assets/images/client/isona-img.jpg"
import altPenedesImg from "../../assets/images/client/altpenedes-img.jpg"
import anoiaImg from "../../assets/images/client/anoia-img.jpg"
import baixPenedesImg from "../../assets/images/client/baixpenedes-img.jpg"
import lleidaImg from "../../assets/images/client/lleida-img.jpg"

class Clients extends Component {
  state = {
    clients: [
      {
        img: laVallImg,
        name: "La Vall Sostenible",
        technology: "Baix Camp",
        link: "https://youtube.com/@lavallsostenible6347/"
      },
      {
        img: ponentImg,
        name: "No a la MAT Ponent",
        technology: "Terres de Ponent",
        link: "https://mobile.twitter.com/nomatponent"
      },
      {
        img: isonaImg,
        name: "No a la MAT Montsó - Isona",
        technology: "Montó - Isona",
        link: "https://autopistaelectricano.blogspot.com"
      },
      {
        img: altPenedesImg,
        name: "No a la MAT Alt Penedès",
        technology: "Alt Penedès",
        link: "https://www.instagram.com/noalamataltpenedes/"
      },
      {
        img: anoiaImg,
        name: "Preservem l'Anoia",
        technology: "Anoia",
        link: "https://www.preservemlanoia.cat/"
      },
      {
        img: baixPenedesImg,
        name: "No a la MAT Baix Penedès",
        technology: "Baix Penedès",
        link: "https://instagram.com/no_a_la_mat?igshid=YmMyMTA2M2Y=/"
      },
      {
        img: lleidaImg,
        name: "Lleida contra la MAT",
        technology: "Segrià",
        link: "https://instagram.com/lleidacontralamat?igshid=YmMyMTA2M2Y="
      },
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      960: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  render() {
    return (
      <React.Fragment>
        <section className="section position-relative" id="other">
          <Container>
            <SectionTitle
              title="Plataformes adherides"
            />

            <div className="row mt-4">
              <OwlCarousel
                className="owl-theme client-images text-center"
                items={2}
                loop={true}
                margin={10}
                nav={false}
                dots={true}
                autoplay={true}
                responsive={this.state.responsive}
                autoplayHoverPause={true}
                autoplayTimeout={2500}
              >
                <ClientBox clients={this.state.clients} />
              </OwlCarousel>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
