import React, { Component } from "react";

class ServiceBox extends Component {
  render() {
    return (
      <div className="service-box mt-4 cursor-pointer" style={{ minHeight: '390px' }} onClick={() => this.props.onClick()}>
        <i className={`${this.props.service.icon} text-purple h1 service-icon`} />
        <h5 className="text-dark font-weight-medium service-title mb-3 mt-5">
          {this.props.service.title}
        </h5>
        <p className="text-muted mb-4 f-15">{this.props.service.summary}</p>
      </div>
    );
  }
}

export default ServiceBox;
